<template>
  <v-container fill-height fluid style='height: 90vh'>
    <v-row no-gutters align='center' justify='center'>
      <v-card tile flat :width='tab === 0 ? 700 : "100%"' color='white'>
        <v-tabs v-model='tab'>
          <v-tab>
            {{ $t('IMPORT_LEGACY') }}
          </v-tab>
          <v-tab>
            {{ $t('REUSAGE_NOT_FOUND') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model='tab'>
          <v-tab-item>
            <RecordReusageForm />
          </v-tab-item>

          <v-tab-item>
            <RecordReusageNotFound v-if='tab === tabs.RECORD_REUSAGE_NOT_FOUND' />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ImportLegacyEntry',
    components: {
      RecordReusageForm: () => import('./RecordReusageForm'),
      RecordReusageNotFound: () => import('./RecordReusageNotFound'),
    },
    data: function () {
      return {
        tabs: {
          RECORD_REUSAGE_FORM: 0,
          RECORD_REUSAGE_NOT_FOUND: 1,
        },
        tab: 0,
      };
    },
  };
</script>
